.navbar {
	background: #fff;
	width: 100%;
	height: 80px;
	text-align: left;
	display: inline-block;
	position: fixed;
	z-index: 100;
	box-shadow: 0px 3px 2px -2px #888;
}

.navcontrols{
	max-width: 1400px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 15px;
	margin-bottom: 0px;
	padding-left: 80px;
}

.navcontrols ul {
	list-style: none;
	padding-left: 0px;
	padding-right: 0px;
}

.navcontrols li{
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	display: inline-block;
	color: #00684a;
	font-size: 20px;
	margin-left: 30px;
}

.navcontrols li:hover{
	cursor: pointer;
}

.NavbarDropDown{
	background-color: #fff;
	position: fixed;
	width: 100%;
	height: 50px;
	z-index: 100;
	box-shadow: 0px 4px 2px -2px #888;
}

.logo{
	width: 90px;
	vertical-align: -25%;
}

.menuIcon{
	display: inline-block;
	width: 35px;
	height: 35px;
	margin-left: 15px;
	margin-right: auto;
	margin-top: 5px;
	margin-bottom: 5px;
}

.menuIcon:hover{
	cursor: pointer;
}



.HideDropDownNav{
	display: none;
}

.DropDownNav{
	position: absolute;
	display: block;
	background-color: #fff;
	margin-top: 0px;
	box-shadow: 3px 3px 3px #555;
	border-bottom-right-radius: 12px;
	z-index: 1;
}


.DropDownNav ul{
	list-style: none;
	padding-left: 0px;
	padding-right: 0px;
	margin-top: 0px;
}

.DropDownNav li{
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 10px;
	padding-right: 10px;
	border-top: 1px solid #555;
	width: 200px;
}

.DropDownNav li:hover{
	cursor: pointer;
	background-color: #eee;
}

.DropDownPasarnowLogo{
	width: 80px;
	position: absolute;
	left: calc(50% - 40px);
	top: 10px;
}

.DropDownPasarnowLogo:hover{
	cursor: pointer;
}


.LanguageDisplay img{
	width: 35px;
	vertical-align: middle;
	box-shadow: 1px 1px 1px #ccc;
	margin-top: -5px;
}

.LanguageDisplaySmall{
	position: absolute;
	width: 35px;
	right: 5%;
	top: 12px;
}

.LanguageDisplaySmall img{
	width: 35px;
	vertical-align: middle;
	box-shadow: 1px 1px 1px #ccc;
}

.LanguageDisplaySmall:hover{
	cursor: pointer;
}