.Distributorship{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 1400px;
	padding-top: 80px;
}

.DistributorshipSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 1400px;
	padding-top: 50px;
}

.pddCover{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0px;
	margin-bottom: 0px;
	width: 100%;
	height: calc(100vh - 80px);
	background-color: #006649;
	background-image: url("../../images/pdd_cover.jpg");
	background-size: cover;
	background-position: left;
	overflow: auto;
}

.pddCover img{
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 400px;
	position: absolute;
	top: calc(50% - 200px);
	left: calc(50% - 500px);
}

.pddCoverText{
	position: absolute;
	top: calc(50% + 30px);
	left: calc(50% - 550px);
}

.pddCoverText h2{
	font-family: serif;
	font-weight: 599;
	font-size: 24pt;
	color: #fff;
	text-shadow: 1px 3px 3px #777;
	width: 500px;
	text-align: center;
	margin-bottom: 0px;
	margin-top: 0px;
}

.pddCoverSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0px;
	margin-bottom: 0px;
	width: 100%;
	height: calc(100vh - 50px);
	background-color: #006649;
	background-image: url("../../images/pdd_cover.jpg");
	background-size: cover;
	background-position: center;
	overflow: auto;
}


.pddCoverSmall img{
	text-align: center;
	font-size: 48pt;
	color: #fff;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	max-width: 400px;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
}

.pddCoverTextSmall{
	position: absolute;
	bottom: 30px;
	left: calc(50% - 150px);
}

.pddCoverTextSmall h2 {
	display: none;
	font-family: serif;
	font-weight: 599;
	font-size: 24pt;
	color: #fff;
	text-shadow: 1px 3px 3px #777;
	width: 300px;
	text-align: center;
	margin-bottom: 0px;
	margin-top: 0px;
}


.warehouseLaptopImage{
	width: 100%;
	max-width: 1400px;
}

/*PDD info*/

.HomePDDInfo h1{
	text-align: center;
	font-size: 40pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 45pt;
	margin-bottom: 15px;
}

.HomePDDInfo h2{
	text-align: center;
	font-size: 26pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	color: #017a59;
	font-weight: 500;
	margin-top: 10px;
}

.HomePDDInfo p{
	display: block;
	margin-right: auto;
	margin-left: auto;
	font-family: Anek;
	font-size: 16pt;
	width: 90%;
	color: #017a59;
}

.PDDInfoBlocks{
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.PDDInfoBlock{
	width: 28%;
	padding-bottom: 10px;
	background-color: #017a59;
	color: #f7ebdb;
	margin-bottom: 80px;
	margin-top: 50px;
	display: inline-block;
}

.PDDInfoBlock img{
	width: 100%;
	margin-bottom: 0px;
	padding: 0;
	display: block;
}

.PDDInfoBlock label{
	display: block;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	font-family: Anek;
	font-size: 24pt;
	text-align: center;
	font-weight: 599;
	vertical-align: middle;
	align-items: center;
}

.PDDInfoMarginRight{
	margin-right: 8%;
}

.HomePDDInfoSmall h1{
	text-align: center;
	font-size: 24pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 24pt;
	margin-bottom: 10px;
}

.HomePDDInfoSmall h2{
	text-align: center;
	font-size: 18pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 20pt;
	color: #017a59;
	font-weight: 500;
	margin-top: 0px;
}

.HomePDDInfoSmall p{
	display: block;
	margin-right: auto;
	margin-left: auto;
	font-family: Anek;
	font-size: 14pt;
	width: 90%;
	color: #017a59;
}

.PDDInfoBlocksSmall{
	width: 100%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	margin-top: -30px;
}

.PDDInfoBlockSmall{
	width: 30%;
	padding-bottom: 10px;
	background-color: #017a59;
	color: #f7ebdb;
	margin-top: 50px;
	display: inline-block;
	margin-left: 1.6%;
	margin-right: 1.6%;
}

.PDDInfoBlockSmall img{
	width: 100%;
	margin-bottom: 0px;
	padding: 0;
	display: block;
}

.PDDInfoBlockSmall label{
	display: block;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	font-family: Anek;
	font-size: 14pt;
	text-align: center;
	font-weight: 599;
	vertical-align: middle;
	align-items: center;
}

/*Key Benefits*/

.KeyBenefitsBlock{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	min-height: 100px;
	background: url("../../images/wh1greennoised.jpg") no-repeat local;
	background-size: cover;
}

.KeyBenefitsHeader{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 90%;
	padding-top: 100px;
}

.KeyBenefitsHeaderH1{
	display: inline-block;
	width: 55%;
	background-color: #ecdab2;
	font-family: Anek;
	color: #006749;
	font-size: 60pt;
	font-weight: 600;
	text-align: center;
	line-height: 70pt;
}

.KeyBenefitsHeaderH3{
	display: inline-block;
	font-family: Anek;
	color: #ecdab2;
	text-align: left;
	padding-left: 20px;
	vertical-align: top;
	font-size: 24pt;
	line-height: 24pt;
	width: calc(45% - 60px);
	font-weight: 550;
}

.BenefitsListBlockTop{
	display: block;
	width: 90%;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 100px;
	padding-top: 50px;
}

.BenefitsListBlock{
	margin-bottom: 30px;
}

.BenefitsListBlockColumn{
	display: inline-block;
	width: 50%;
	vertical-align: text-top;
}

.BenefitsListNumber{
	background-color: #f9c160;
	width: 25px;
	height: 25px;
	text-align: center;
	border-radius: 100px;
	font-family: Anek;
	font-weight: 590;
	color: #006648;
	display: inline-block;
	font-size: 13pt;
	position: relative;
}

.BenefitsListTitle{
	display: inline-block;
	background-color: #ecdab2;
	color: #006648;
	font-family: Anek;
	font-weight: 590;
	padding: 0 10px;
	font-size: 18pt;
	line-height: 20pt;
	vertical-align: text-top;
	margin-top: 10px;
	margin-left: -10px;
}

.BenefitsListContent{
	display: block;
	margin-left: 17px;
	margin-right: 10px;
	margin-top: 5px;
	font-family: Anek;
	color: #fff;
	font-size: 14pt;
}

.KeyBenefitsHeaderH1Small{
	display: block;
	width: 100%;
	background-color: #ecdab2;
	font-family: Anek;
	color: #006749;
	font-size: 40pt;
	font-weight: 600;
	text-align: center;
	line-height: 70pt;
}

.KeyBenefitsHeaderH3Small{
	display: block;
	font-family: Anek;
	color: #ecdab2;
	text-align: center;
	vertical-align: top;
	font-size: 24pt;
	line-height: 24pt;
	width: 100%;
	font-weight: 550;
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
}

.BenefitsListBlockColumnSmall{
	display: block;
	width: 100%;
	vertical-align: text-top;
}

/*Explore our Market Footprint*/

.OurMarketFootprintTop{
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #fff;
}



.OurMarketFootprintTop h1{
	text-align: center;
	font-size: 40pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 45pt;
	margin-bottom: 15px;
}

.OurMarketFootprintTop h2{
	text-align: center;
	font-size: 26pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	color: #017a59;
	font-weight: 500;
	margin-top: 10px;
}

.OurMarketFootprintTopSmall{
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 20px;
	padding-bottom: 50px;
	background-color: #fff;
}


.OurMarketFootprintTopSmall h1{
	text-align: center;
	font-size: 24pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 24pt;
	margin-bottom: 10px;
}

.OurMarketFootprintTopSmall h2{
	text-align: center;
	font-size: 18pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 20pt;
	color: #017a59;
	font-weight: 500;
	margin-top: 0px;
}

.MarketFootPrintRow{
	display: block;
	text-align: center;
	margin-bottom: 20px;
}

.MarketFootPrintRow img{
	display: inline-block;
	max-width: 100px;
	width: calc((100%/8) - 30px);
	vertical-align: middle;
	
}

.MarketFootPrintRow label{
	display: block;
	margin-right: auto;
	margin-left: auto;
	margin-top: 30px;
	margin-bottom: 30px;
	font-family: Anek;
	font-size: 16pt;
}

.MarketFootPrintRowOne{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	text-align: center;
	width: 100%;
}

.MarketFootPrintRowOne img{
	display: inline-block;
	width: 22%;
	max-width: 300px;
	margin-left: 1%;
	margin-right: 1%;
}

.MarketFootPrintRowOneSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	text-align: center;
	width: 100%;
}

.MarketFootPrintRowOneSmall img{
	display: block;
	width: 70%;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
}

.MarketFootPrintRowTwo{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	text-align: center;
}

.MarketFootPrintRowTwo img{
	display: inline-block;
	width: 22%;
	max-width: 300px;
	margin-left: 1%;
	margin-right: 1%;
}

.MarketFootPrintRowTwoSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	text-align: center;
	width: 100%;
}

.MarketFootPrintRowTwoSmall img{
	display: block;
	width: 70%;
	max-width: 400px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
}


.MarketFootprintLogoMarginRight{
	margin-right: 30px;
}

.MarketFootPrintRowSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.MarketFootPrintRowSmall img{
	display: inline-block;
	max-width: 100px;
	width: calc((100%/4) - 30px);
	vertical-align: middle;
	margin-bottom: 20px;
}

.MarketFootPrintRowSmall label{
	display: block;
	margin-right: auto;
	margin-left: auto;
	margin-top: 30px;
	margin-bottom: 30px;
	font-family: Anek;
	font-size: 16pt;
}

#ValueProposition{
	background-color: #fff;
	padding-top: 50px;
	padding-bottom: 50px;
	margin-top: 0px;
}

.valuePropositions h1{
	text-align: center;
	font-size: 40pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 80%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 45pt;
	margin-bottom: 15px;
}

.valuePropositions h2{
	text-align: center;
	font-size: 24pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	color: #017a59;
	font-weight: 500;
	margin-top: 10px;
}

.valuePropositionImages{
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-bottom: 50px;
}

.valuePropositionImages img{
	display: inline-block;
	width: 30%;
	max-width: 350px;
	margin-left: 2.5%;
}

.valuePropositionsSmall h1{
	text-align: center;
	font-size: 24pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 80%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 24pt;
	margin-bottom: 30px;
	margin-top: 0px;
}

.valuePropositionsSmall h2{
	text-align: left;
	font-size: 16pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 20pt;
	color: #017a59;
	font-weight: 500;
	margin-top: 0px;
}

.valuePropositionImagesSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-bottom: 50px;
}

.valuePropositionImagesSmall img{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 30px;
	width: 80%;
	max-width: 350px;
}

.OurProducts{
	background-color: #fbf5f0;
	margin-bottom: 0px;
	padding-top: 50px;
	padding-bottom: 50px;
}

.OurProducts h1{
	text-align: center;
	font-size: 40pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 80%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 45pt;
	margin-bottom: 15px;
}

.OurProducts h2{
	text-align: center;
	font-size: 24pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	color: #017a59;
	font-weight: 500;
	margin-top: 10px;
}

.OurProducts img{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	margin-bottom: 50px;
	width: 80%;
}

.OurProductsSmall{
	background-color: #fbf5f0;
	margin-bottom: 0px;
	padding-top: 20px;
	padding-bottom: 50px;
}

.OurProductsSmall h1{
	text-align: center;
	font-size: 24pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 80%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 24pt;
	margin-bottom: 30px;
}

.OurProductsSmall h2{
	text-align: left;
	font-size: 16pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 20pt;
	color: #017a59;
	font-weight: 500;
	margin-top: 0px;
}

.OurProductsSmall img{
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 50px;
	margin-bottom: 50px;
	width: 80%;
}

.distributionCapabilities{
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	background-color: #fbf5f0;
	padding-top: 50px;
}

.distributionCapabilities h1{
	text-align: center;
	font-size: 40pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 45pt;
	margin-bottom: 15px;
}

.distributionCapabilities h2{
	text-align: center;
	font-size: 26pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	color: #017a59;
	font-weight: 500;
	margin-top: 10px;
}

.distributionCapabilitiesSmall{
	display: block;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	background-color: #fbf5f0;
	padding-top: 50px;
}

.distributionCapabilitiesSmall h1{
	text-align: center;
	font-size: 24pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 24pt;
	margin-bottom: 10px;
}

.distributionCapabilitiesSmall h2{
	text-align: center;
	font-size: 18pt;
	font-family: Anek;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 20pt;
	color: #017a59;
	font-weight: 500;
	margin-top: 0px;
}

.distributionCapabilitiesBlockTop{
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-bottom: 30px;
}

.distributionCapabilitiesBlock{
	display: inline-block;
	text-align: center;
	width: 50%;
	padding-top: 10px;
}

.distributionCapabilitiesBlock h2{
	font-family: Anek;
	font-weight: 599;
	color: #006648;
	font-size: 16pt;
	margin-bottom: 0px;
	margin-top: 0px;
	display: block;
	text-align: center;
}

.distributionCapabilitiesBlock label{
	font-family: Anek;
	font-weight: 400;
	color: #006648;
	font-size: 16pt;
	margin-bottom: 0px;
	display: block;
	text-align: center;
}


.distributionCapabilitiesBlock img{
	max-width: 120px;
}

.distributionCapabilitiesBlockRow{
	display: inline-block;
	width: 50%;
	margin-top: 50px;
	margin-bottom: 50px;
}

.distributionCapabilitiesBlockTopSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-bottom: 30px;
	margin-top: 30px;
}

.distributionCapabilitiesBlockSmall{
	display: inline-block;
	text-align: center;
	width: 50%;
	padding-top: 10px;
}

.distributionCapabilitiesBlockSmall h2{
	font-family: Anek;
	font-weight: 599;
	color: #006648;
	font-size: 14pt;
	margin-bottom: 0px;
	margin-top: 0px;
	display: block;
	text-align: center;
}

.distributionCapabilitiesBlockSmall label{
	font-family: Anek;
	font-weight: 400;
	color: #006648;
	font-size: 14pt;
	margin-bottom: 0px;
	display: block;
	text-align: center;
}

.distributionCapabilitiesBlockRowSmall{
	display: block;
	width: 100% !important;
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
}

.distributionCapabilitiesBlockSmall img{
	width: 70%;
	max-width: 120px;
}

.distributionCapabilitiesBottomDivider{
	display: block;
	text-align: center;
	margin-top: 180px;
}

.distributionCapabilitiesBottomDivider img{
	width: 300px;
	position: absolute;
	left: calc(50% - 150px);
	margin-top: -100px;
}

.FloorBackground{
	display: block;
	margin-top: 50px;
	width: 100%;
	height: 90px;
	background-color: #cdb498;
}

.contactUs{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding-top: 0px;
	padding-bottom: 50px;
}



.contactUs h1{
	text-align: center;
	font-size: 40pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 45pt;
	margin-bottom: 15px;
}

.contactUsSmall{
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding-top: 0px;
	padding-bottom: 50px;
}

.contactUsSmall h1{
	text-align: center;
	font-size: 24pt;
	color: #017a59;
	text-transform: uppercase;
	font-family: Anek;
	font-weight: 599;
	font-style: normal;
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	line-height: 24pt;
	margin-bottom: 10px;
}

.GoogleMaps{
	display: block;
	width: 90%;
	height: 40vw;
	min-height: 400px;
	max-height: 600px;
	margin-left: auto;
	margin-right: auto;
}

.ContactBodyHome{
	width: 90%;
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 30px;
	padding-top: 10px;
}

.ContactColumnHome{
	width:33.33%;
	display: inline-block;
	vertical-align: text-top;
}

.ContactColumnHome h2{
	font-family: Anek;
	font-weight: 599;
	display: block;
	color: #006649;
	font-size: 16pt;
	line-height: 16pt;
	margin-bottom: 0;
	padding-bottom: 5px;
}

.ContactColumnHome label{
	margin-top: 0px;
	color: #006649;
	font-family: Anek;
	display: block;
	width: 80%;
}

.ContactColumnHomeSmall{
	width: 100%;
	display: block;
}

.ContactColumnHomeSmall h2{
	font-family: Anek;
	font-weight: 599;
	display: block;
	color: #006649;
	font-size: 22pt;
	line-height: 20pt;
	margin-bottom: 0;
	padding-bottom: 5px;
}

.ContactColumnHomeSmall label{
	margin-top: 0px;
	color: #006649;
	font-family: Anek;
	display:
}